.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 80vh;
    text-align: center;
    padding: 1rem;
    width: 100%;
    max-width: 97vw;
    overflow-x: hidden;
}

.recording-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
    width: 100%;
    max-width: 400px;
    padding: 0 1rem;
}

.pause-resume-button,
.stop-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    flex: 0 0 auto;
}

.pause-resume-button img,
.stop-button img {
    width: 3rem;
    height: 3rem;
}

.recording-time {
    font-size: 1.5rem;
    margin: 0;
    margin-top: 0px;
    flex: 0 0 auto;
}

.button-spacer {
    flex: 1 1 auto;
    min-width: 2rem;
}

.record-button {
    position: absolute;
    bottom: 5%;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
}

.record-button img {
    width: 5rem;
    height: 5rem;
}

.initial-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 60%;
}

.vent-message,
.user-message {
    font-size: 10vw;
    margin: 1rem 0;
}

.preview-text-container {
    display: flex;
    margin-right: 20%;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: visible;
}

.Preview-Text {
    margin-right: 400px;
    font-size: 10rem;
    margin: 1rem 0;
    white-space: nowrap;
    width: 100%;
    text-align: center;
}

.preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 400px;
}

.preview-buttons {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    margin-top: 0.5rem;
    flex-wrap: wrap;
}

.new-recording-button:hover,
.submit-recording-button:hover {
    opacity: 0.9;
}

h2 {
    margin-top: 0.5rem;
}

audio {
    margin-top: 0.5rem;
    width: 100%;
}

.title-input {
    margin-top: 0.5rem;
    padding: 1rem;
    border-radius: 10px;
    width: 100%;
    box-sizing: border-box;
    font-size: 1.2rem;
    line-height: 1.5;
    min-height: 3.5rem;
}

button {
    margin-top: 0.5rem;
    padding: 0.5rem 1rem;
    border-radius: 10px;
}

p {
    margin-top: 0.5rem;
}

@media (max-width: 1200px) {
    .Preview-Text {
        font-size: 12rem;
    }
}

@media (max-width: 992px) {
    .Preview-Text {
        font-size: 10rem;
    }
}

@media (max-width: 768px) {
    .vent-message,
    .user-message {
        font-size: 10vw;
    }

    .Preview-Text {
        font-size: 8rem;
    }

    .record-button img {
        width: 4rem;
        height: 4rem;
    }

    .recording-controls {
        padding: 0 0.5rem;
    }

    .pause-resume-button img,
    .stop-button img {
        width: 2.5rem;
        height: 2.5rem;
    }

    .recording-time {
        font-size: 1.25rem;
    }

    .button-spacer {
        min-width: 1rem;
    }
}

@media (max-width: 480px) {
    .vent-message,
    .user-message {
        font-size: 8vw;
    }

    .Preview-Text {
        font-size: 5rem;
    }

    .record-button img {
        width: 3.5rem;
        height: 3.5rem;
    }

    .pause-resume-button img,
    .stop-button img {
        width: 2rem;
        height: 2rem;
    }

    .recording-time {
        font-size: 1rem;
    }

    .button-spacer {
        min-width: 0.5rem;
    }

    .title-input {
        font-size: 1rem;
        padding: 0.8rem;
    }
}

@media (max-width: 320px) {
    .Preview-Text {
        font-size: 4rem;
    }
}
