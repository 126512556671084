#logo {
    font-size: 50px;
    font-weight: 300;
    margin-top: 30px;
    margin-left: 30px;
}
#logo a {
    text-decoration: none;
    color: black;
}

#logo p {
    font-size: 25px;
    margin: 0;
}
