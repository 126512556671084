@import url('https://fonts.googleapis.com/css2?family=Josefin+Slab:ital,wght@0,100..700;1,100..700&display=swap');

body {
    margin: 0;
    background-color: #A4F0C2;
    font-family: "Josefin Slab", serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: 600;
    color: black;
}
main {
    display: flex;
    justify-content: center; /* Center the container horizontally */
    align-items: center; /* Center the container vertically */
    height: calc(100vh - 120px); /* Adjust the height to leave space for header and footer */
    flex-direction: column; /* Stack children vertically */
}

#tapes-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center items horizontally */
    padding: 20px;
}

.tape-text {
    opacity: 0;
    transition: opacity 0.3s;
    position: absolute; /* Position the text relative to the tape */
    top: 50%; /* Adjust vertically */
    left: 50%; /* Adjust horizontally */
    transform: translate(-50%, -50%); /* Center the text */
    font-size: 25px; /* Customize font size */
    color: black; /* Customize text color */
}

#tapes {
    position: relative;
    display: flex;
    flex-wrap: wrap; /* Allow wrapping */
    justify-content: center; /* Center items */
    width: 100%;
    margin: auto;
    overflow: hidden;
}

.tape {
    position: relative;
    padding: 20px;
    text-align: center;
    flex: 1 1 250px; /* Flex-grow, flex-shrink, and flex-basis */
    margin: 10px;
    transition: background-color 0.3s, transform 0.3s;
    max-width: 250px;
}

.tape:hover .tapeDisappear, .tape-hover .tapeDisappear {
    opacity: 0;
}

.tape:hover .tape-text, .tape-hover .tape-text {
    opacity: 1;
}

.tape img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 10px;
}

#record-button {
    background-color: #FE7271;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px; /* Add margin to create space between tapes and button */
    cursor: pointer;
    border: none;
}

#record-button img {
    width: 50px;
    height: 50px;
}

#refresh-button {
    background-color: #5ED0DA;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px; /* Add margin to create space between tapes and button */
    cursor: pointer;
    border: none;
}

#refresh-button img {
    width: 30px;
    height: 30px;
}
#refresh-button:active {
    transform: translateY(4px);
  }

#btns-container {
    display: flex;
    justify-content: space-between; 
}

.btn {
    margin: 20px;
}
.btn:hover {
    opacity: 0.60;
}

.loading-screen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 75vh;
    background-color: #A4F0C2;
  }
  
  .loading-spinner {
    width: 50px;
    height: 50px;
    border: 5px solid #333;
    border-top: 5px solid #007bff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  .loading-text {
    margin-top: 20px;
    font-size: 18px;
    font-weight: bold;
    color: #333;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
    z-index: 1000;
}

.popup button {
    margin-top: 10px;
}
/* Media Queries for Responsive Design */
@media (max-width: 768px) {
    .tape {
        flex: 1 1 200px; /* Adjust flex-basis for smaller screens */
        max-width: 200px;
    }

    .tape-text {
        font-size: 14px; /* Adjust font size */
    }
}

@media (max-width: 480px) {
    .tape {
        flex: 1 1 150px; /* Adjust flex-basis for very small screens */
        max-width: 150px;
    }

    .tape-text {
        font-size: 12px; /* Adjust font size */
    }

    #record-button {
        width: 60px;
        height: 60px;
    }

    #record-button img {
        width: 30px;
        height: 30px;
    }
    #refresh-button {
        width: 60px;
        height: 60px;
    }

    #refresh-button img {
        width: 10px;
        height: 10px;
    }
}

